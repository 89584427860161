import React from "react";
import CloseIcon from '@material-ui/icons/Close';

import './MobileLayout.less';

const MobileSort = (props) => {
  const { closeSort, options } = props;

  return (
    <div className="m-sort-container">
      <div className="sort-header">
        <div>Sort</div>
        <CloseIcon fontSize="small" onClick={() => closeSort()}/>
      </div>
      <div className="sort-content">
        {
          options.map(el => 
          <div 
            className={`sort-item ${el.active? 'active' : null}`}
            key={el.key} 
            onClick={el.onClick}
          >
            {el.label}
          </div>)
        }
      </div>
    </div>
  )
}

export default MobileSort;