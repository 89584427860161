import React, { useEffect } from 'react'
import {compose} from 'recompose';
import {inject,observer} from 'mobx-react';
import Masonry from 'react-masonry-component';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import {motion,AnimatePresence} from 'framer-motion';
import { useTheme,makeStyles,createStyles,withStyles } from '@material-ui/core/styles';

import PropertyCard from '@src/components/property/PropertyCard';
import ProjectCard from '@src/components/property/ProjectCard';
import {propertySlugGeneratorFromRaw} from '@src/utils/slug';

const motionProps = {
  key: 'exploreList',
  initial: { opacity: 0, x: 200 },
  animate: {
    opacity: 1,
    x: 0
  },
  transition: { duration: 0.5 }
};
const cardWidth = 368;

const ExplorePropertyList = (props) => {
  const theme = useTheme()
  const styles = useStyles()

  const {propertyStore, uiStore,filterStore, motionProps} = props;
  const {
    propertyList,
    getMapProperties_,
    favorProperty_,
    unfavorProperty_,
    toggleFavorByIndex
  } = propertyStore;
  const {setCurrentListHoverListingId, checkLogin} = uiStore

  useEffect(() => {
    getMapProperties_()
  }, [])

  const masonryOptions = {
    columnWidth: cardWidth
  };

  const boxShadow = {boxShadow:`0px 4px 10px 0px ${theme.palette.grey[400]}`};

  const onHoverAnimation = {
    x:-2,
    y:-2,
    boxShadow:`2px 6px 12px 0px ${theme.palette.grey[500]}`
  };

  const onHoverStart = (id)=>(e,info)=>{
    setCurrentListHoverListingId(id)
  };

  const onHoverEnd = ()=>{
    setCurrentListHoverListingId(null)
  };

  const onToggleFavor = (listingId,index)=>(newState)=>{
    if(newState===true){
        favorProperty_(listingId)
    }else{
        unfavorProperty_(listingId)
    }
    toggleFavorByIndex(newState,index)
  }

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div className={styles.root} {...motionProps}>
        <Masonry options={masonryOptions}>
          {propertyList.map((data,index)=>
            <div className={styles.card} key={data.rawData.id}>
              {data.processedData.isProject?
                <ProjectCard
                  project={data.processedData}
                  listMode={false}
                  rootStyle={boxShadow}
                  favored={data.processedData.isSaved}
                  motionProps={{
                    whileHover: onHoverAnimation,
                    onHoverStart:onHoverStart(data.processedData.listingId),
                    onHoverEnd,
                  }}
                  onClick={()=>window.location.href = `/${propertySlugGeneratorFromRaw(data.rawData)}`}
                  onToggleFavor={checkLogin(onToggleFavor(data.processedData.listingId,index))}
                />
                :
                <PropertyCard
                  onClick={()=>window.location.href = `/${propertySlugGeneratorFromRaw(data.rawData)}`}
                  property={data.processedData}
                  rootStyle={boxShadow}
                  favored={data.processedData.isSaved}
                  onToggleFavor={checkLogin(onToggleFavor(data.processedData.listingId,index))}
                  motionProps={{
                    whileHover: onHoverAnimation,
                    onHoverStart:onHoverStart(data.processedData.listingId),
                    onHoverEnd
                  }}
                />
              }
            </div>
          )}
        </Masonry>
      </motion.div>
    </AnimatePresence>
  )
}

const useStyles = makeStyles((theme) => createStyles({
  root:{
      flex: '0 0',
      flexBasis:cardWidth+16*2,
      position:'relative',
      [theme.breakpoints.up('tablet')]: {
          flexBasis:2*cardWidth+16*3
      },
  },
  btn:{
      marginRight:8,
      fontWeight:500,
  },
  btnGroup:{
      ...hBox('center'),
      padding:16,
      height:64,
      backgroundColor:'white',
      position:'absolute',
      left:0,
      right:0,
      top:0,
      zIndex:2,
  },
  card:{
      marginBottom: 16,
      width: '100vw'
  },
  container:{
      overflow:'auto',
      overflow:'overlay'
  },
  header:{
      ...hBox('center','space-between'),
      padding:8,
      paddingRight:16,
      paddingLeft:16,
  }
}))

export default compose(inject('propertyStore','uiStore','filterStore'),observer)(ExplorePropertyList)
