import React, {useEffect, useState} from 'react';
import {compose} from 'recompose';
import {inject,observer} from 'mobx-react';
import {navigate} from "gatsby";
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import ImportExportOutlinedIcon from '@material-ui/icons/ImportExportOutlined';

import withRoot from '../withRoot';
import MobileHeadBar from '../components/mobile/MobileHeadBar';
import ExplorePropertyList from '../components/mobile/ExplorePropertyList';
import {ReactComponent as FilterIcon} from '@src/assets/svgIcons/Filter 1.svg';
import LoginModal from '@src/components/LoginModal';
import MobileFilter from '@src/components/mobile/MobileFilter';
import SearchBar from '@src/components/mobile/SearchBar';
import MobileSort from '@src/components/mobile/MobileSort';

import '../components/mobile/MobileLayout.less';

const ExploreList = ({uiStore, filterStore, propertyStore})=> {
  const [showSort, setShowSort] = useState(false);
  const {openLogin,setOpenLogin} = uiStore;
  const {setShowMobileFilter, FILTER_SORT_ORDER, setListViewSortBy, listViewSortBy} = filterStore;
  const {getMapProperties_  } = propertyStore;

  useEffect(() => {
      uiStore.setExplorePageCurrentTab('explore')
  }, [])

  const onSearch = () => {
    getMapProperties_()
  }

  const closeSort = () => {
    setShowSort(false)
  }

  const options = FILTER_SORT_ORDER.map(item=>({
    label: item.title,
    value: item.value,
    key: item.title,
    active: listViewSortBy === item.value,
    onClick:(e)=>{
      closeSort(e)
      setListViewSortBy(item.value)
      getMapProperties_()
    }
  }))


  return (
    <div>
      <MobileHeadBar />
      <div className='m-search-filter'>
        <SearchBar searchButtonClick={onSearch}/>
        <div className='filter-sort'>
          <div className='item filter' onClick={() => setShowMobileFilter(true)}>
            <FilterIcon height={15} width={16}/>
            <span style={{marginLeft: 6}}>Filter</span>
          </div>
          <div className='item map'>
            <div onClick={() => navigate('/exploreMap')}>
              <MapOutlinedIcon height={16} width={16}/>
              <span style={{marginLeft: 6}}>Map</span>
            </div>
          </div>
          <div className='item' onClick={() => setShowSort(true)}>
            <ImportExportOutlinedIcon height={11} width={15}/>
            <span>Sort</span>
          </div>
        </div>
      </div>
      <div className='m-properties-list'>
        <div className='label'>Available Properties</div>
        <ExplorePropertyList />
      </div>
      <LoginModal visible={openLogin} handleCancel={()=>setOpenLogin(false)} />
      <MobileFilter />
      {showSort&&<MobileSort closeSort={closeSort} options={options}/>}
    </div>
  )
};

export default compose(withRoot, inject('uiStore', 'filterStore', 'propertyStore'), observer)(ExploreList)
